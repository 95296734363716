import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActiveCall, ActiveCallsWebsocket } from 'src/app/model/Calls';
import { ClientDetails } from 'src/app/model/ClientDetails';
import { KayoolMeetService } from 'src/app/modules/kayool-meet/service/kayool-meet.service';
import { VideoBroadcastService } from 'src/app/modules/kayool-meet/service/video-broadcast.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { VideoCallFormComponent } from '../video-call-form/video-call-form.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  title = 'kayool-meet';
  startVideoCall: boolean = false;
  activeCall: ActiveCall = null;
  clientID: string = null;
  departmentID: string = null;
  showPageLoader: boolean = false;
  clientIdError: boolean = false;
  showCloseButton: boolean = true;

  private sessionStopSubscription: Subscription;
  private customerCallsSubscription: Subscription;
  private clientRemovedOrDisabledSubscription: Subscription;
  private paramaMapSubscription: Subscription;

  constructor(
    private videoBroadcastService: VideoBroadcastService,
    private dialog: MatDialog,
    private kayoolMeetService: KayoolMeetService,
    private websocketService: WebsocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('call')) {
      // website is loaded from the video call url
      // getting clientId/uniqueName from the address
      this.paramaMapSubscription = this.activatedRoute.paramMap.subscribe(
        (params) => {
          console.log('website is loaded from the video call url');
          this.showCloseButton = false;
          if (this.router.url.includes('/call/u/')) {
            // unique name available for video call url
            this.getClientDeptDetailsByUniqueName(params.get('uname'));
          } else {
            this.clientID = params.get('clientId');
            this.departmentID = params.get('deptId');
            this.getEnterpriseClientDetailsById(this.clientID);

            // show the modal to start call only if the enterprise client id is available
            if (this.clientID != null) this.onClickShowViaVideoCall();
          }
        }
      );
    } else {
      // website is loaded from the script
      console.log('website is loaded from the script');
      this.clientID = this.websocketService.getEnterpriseClientData().clientID;
      this.departmentID =
        this.websocketService.getEnterpriseClientData().deptID;
      this.getEnterpriseClientDetailsById(this.clientID);
      // show the modal to start call only if the enterprise client id is available
      if (this.clientID != null) this.onClickShowViaVideoCall();
    }

    if (this.clientID)
      this.websocketService.initWebsocketConnection(this.clientID);

    try {
      window.top.postMessage('onLoadIframe', '*');
    } catch (error) {
      console.error('postMessage Error', error);
    }

    this.customerCallsSubscription = this.websocketService
      .getCustomerCallObservable()
      .subscribe((res: ActiveCallsWebsocket) => {
        if (res.delete) {
          console.log('call stopped by shop');
          if (this.activeCall && this.activeCall.id == res.call.id) {
            //stop call
            this.videoBroadcastService.stopActiveBroadcast();
            this.activeCall = null;
          }
        }
      });

    this.sessionStopSubscription = this.kayoolMeetService
      .getSessionStopObservable()
      .subscribe((showNotAvailableDialog: boolean) => {
        if (this.activeCall) {
          console.log('self stop');
          this.activeCall = null;
          this.websocketService
            .stopMyCall()
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
        }

        if (showNotAvailableDialog) {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Call Disconnected',
              message: `All our customer care executives are very busy.
              You can submit a request to get a callback from our team or please call again after some time.`,
              clientID: this.clientID,
            },
            disableClose: true,
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.closeIframe();
          });
        } else this.closeIframe();
      });

    this.clientRemovedOrDisabledSubscription = this.websocketService
      .getClientRemovedOrDisabledObservable()
      .subscribe((res) => {
        this.clientID = null;
      });
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
    if (this.sessionStopSubscription)
      this.sessionStopSubscription.unsubscribe();

    if (this.customerCallsSubscription)
      this.customerCallsSubscription.unsubscribe();

    if (this.clientRemovedOrDisabledSubscription)
      this.clientRemovedOrDisabledSubscription.unsubscribe();

    if (this.paramaMapSubscription) this.paramaMapSubscription.unsubscribe();
  }

  getClientDeptDetailsByUniqueName(uName: string) {
    console.log('**** getClientDeptDetailsByUniqueName() called ****', uName);

    this.websocketService
      .getClientDeptDetailsByUniqueNameApi(uName)
      .then(
        (res: {
          client: { id: string; active: boolean };
          department: { id: string };
        }) => {
          if (res.client.active) {
            this.clientID = res.client.id;
            if (res?.department) this.departmentID = res.department.id;
            this.onClickShowViaVideoCall();
          } else {
            this.showErrorMessage();
            return;
          }
        }
      )
      .catch((err) => {
        console.error(err);
        if (err.error.message == 'Not found') {
          this.showErrorMessage();
          return;
        }
        setTimeout(() => {
          this.getClientDeptDetailsByUniqueName(uName);
        }, 4000);
      });
  }

  getEnterpriseClientDetailsById(id: string) {
    console.log('**** getEnterpriseClientDetailsById() called ****', id);

    this.websocketService
      .getClientDetailsByIdApi(id)
      .then((res: ClientDetails) => {
        if (res.active) this.clientID = res.id;
        else {
          this.showErrorMessage();
          return;
        }
      })
      .catch((err) => {
        console.error(err);
        if (
          err.error.message == 'Invalid Client ID' ||
          err.error.message == 'Client not exist'
        ) {
          this.showErrorMessage();
          return;
        }
        setTimeout(() => {
          this.getEnterpriseClientDetailsById(id);
        }, 4000);
      });
  }

  showErrorMessage() {
    this.clientID = null;
    this.departmentID = null;
    this.clientIdError = true;
    this.showPageLoader = false;
    this.dialog.closeAll();
  }

  closeIframe() {
    try {
      window.top.postMessage('closeKayoolMeet', '*');
    } catch (error) {
      console.error('postMessage Error', error);
    }
    // show the modal to start call only if the enterprise client id is available
    if (this.clientID != null) this.onClickShowViaVideoCall();
  }

  onBroadcast(start: boolean) {
    this.showPageLoader = false;
    this.startVideoCall = start;
  }

  onClickShowViaVideoCall() {
    console.log('**** onClickShowViaVideoCall() called ****');

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { clientID: this.clientID, deptId: this.departmentID };

    const dialogRef = this.dialog.open(VideoCallFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.activeCall = dialogRef.componentInstance.activeCall;
      if (!this.clientIdError)
        this.showPageLoader = !dialogRef.componentInstance.isCancelled;
      this.clientIdError = false;
    });
  }
}
