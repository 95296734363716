/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BroadcastConfig } from '../model/model';

const BROADCAST_CONTAINER = 'broadcast-container';
const BROADCAST_HOME = 'broadcast-home';

@Injectable({
  providedIn: 'root',
})
export class VideoBroadcastService {
  broadcasting: boolean = false;
  broadcastWatching: boolean = false;
  broadcastingChannel: any = null;
  autoReconnectChannel: any = null;
  startBroadcastInQueue: boolean = false;
  watchBroadcastInQueue: boolean = false;
  broadcastConfig: any = null;
  timeLeft: number = 0;
  interval: any;
  timeOutDuration: number = 0;
  waitForHost: boolean = false;
  showingInAotWindow: boolean = false;
  private broadcastListener = new Subject<BroadcastConfig>();
  private broadcastStopListener = new Subject<string>();
  private broadcastWatchStopListener = new Subject<string>();
  private broadcastMiniShowHideListener = new Subject<boolean>();
  private stopBroadcastWatchingActionListener = new Subject<boolean>();
  private stopBroadcastActionListener = new Subject<boolean>();
  private broadcastModuleRefreshListener = new Subject<boolean>();
  private fullScreenSwitchListener: Subject<boolean> = new Subject<boolean>();
  private timerListener = new Subject<boolean>();
  private liveClassNavigationObserver = new Subject<string>();

  constructor() {}

  getLiveClassNavigationObservable() {
    return this.liveClassNavigationObserver.asObservable();
  }

  triggerLiveclassNavigation(view: string) {
    this.liveClassNavigationObserver.next(view);
  }

  getBroadcastistener() {
    return this.broadcastListener.asObservable();
  }

  getBroadcastStopListener() {
    return this.broadcastStopListener.asObservable();
  }

  getBroadcastWatchStopListener() {
    return this.broadcastWatchStopListener.asObservable();
  }

  getBroadcastModuleRefreshListener() {
    return this.broadcastModuleRefreshListener.asObservable();
  }

  getStopBroadcastWatchActionListener() {
    return this.stopBroadcastWatchingActionListener.asObservable();
  }

  getStopBroadcastActionListener() {
    return this.stopBroadcastActionListener.asObservable();
  }

  getBroadcastMiniShowHideLIstener() {
    return this.broadcastMiniShowHideListener.asObservable();
  }

  getFullScreenSwitchListener() {
    return this.fullScreenSwitchListener.asObservable();
  }

  /**
   * get broadcasting/broadcast watching listener
   */
  getBroadcastingChannel() {
    return this.broadcastingChannel;
  }

  /**
   * this method will return true if broadcasting
   */
  isBroadcasting() {
    return this.broadcasting;
  }

  /**
   * this method will return true if user watching a broadcast
   */
  isBroadcastWatching() {
    return this.broadcastWatching;
  }

  pauseTimer() {
    this.timeLeft = 0;
    clearInterval(this.interval);
  }

  /**
   * on stop broadcast return broadcast container to its home position
   * if BroadcastMini is in open stage, then close
   */
  onStopBroadcast() {
    this.pauseTimer();
    this.broadcasting = false;
    this.broadcastingChannel = null;
    this.broadcastConfig = null;
    this.broadcastModuleRefreshListener.next(true);
  }

  /**
   * on stop broadcast return broadcast container to its home position
   * if BroadcastMini is in open stage, then close
   */
  onStopViewingBroadcast() {
    let home: any = document.getElementById(BROADCAST_HOME);
    home.appendChild(document.getElementById(BROADCAST_CONTAINER));

    this.broadcastWatchStopListener.next(this.broadcastingChannel.id);
    this.broadcastWatching = false;
    this.broadcastingChannel = null;
    this.broadcastConfig = null;
    this.broadcastModuleRefreshListener.next(true);
  }

  stopBroadcastSession() {
    console.log('stop broadcast session called');

    this.stopWatchingBroadcast();
    this.stopActiveBroadcast();
  }
  /**
   * to stop watching broadcast session
   */
  stopWatchingBroadcast(autoreconnectEnabled: boolean = false) {
    if (autoreconnectEnabled) {
      this.autoReconnectChannel = this.broadcastingChannel;
    } else {
      this.autoReconnectChannel = null;
    }
    if (this.broadcastWatching) {
      this.stopBroadcastWatchingActionListener.next(true);
    }
  }

  /**
   * stop active broadcastsession
   */
  stopActiveBroadcast() {
    if (this.broadcasting) {
      this.stopBroadcastActionListener.next(true);
    }
  }

  setWaitForHost(waitForHost: any) {
    return waitForHost;
  }
  gettimerObservable() {
    return this.timerListener.asObservable();
  }
  jointimerObservableFunction(value: boolean) {
    this.waitForHost = value;
    this.timerListener.next(value);
  }

  /**
   * set broadcasting channel and broadcasting variable true
   * move video broadcast window to parentNode user given
   * and call startBroadcast in in broadcaster component
   * @param broadcastConfig
   * @param channel
   * @param start
   */
  broadcast(broadcastConfig: BroadcastConfig) {
    if (!this.broadcasting && !this.broadcastWatching) {
      /**
       * no active broadcast
       */
      // this.broadcastingChannel = channel;
      this.broadcastConfig = broadcastConfig;
      this.broadcasting = true;
      // this.setBroadcastViewToClassroomPage();
      this.broadcastListener.next(broadcastConfig);
    } else {
      /**
       * there is a broadcast running
       * stop that and start new one
       */
      this.stopBroadcastSession();
    }
  }
}
