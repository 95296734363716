<!-- /*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arungirivasan@gmail.com>, June 2019
 ******************************************************************************/ -->
<!-- style="display: none" -->
<div
  id="broadcast-home"
  class="h-100 w-100 bg-black"
  [ngStyle]="{ display: startVideoCall ? 'block' : 'none' }"
>
  <div
    id="broadcast-container"
    (mousemove)="mouseoverEvent()"
    (click)="mouseoverEvent()"
    #fullScrBroadcast
    class="broadcast-container-mobile"
  >
    <!-- show error alerts -->
    <app-km-error-alerts></app-km-error-alerts>
    <app-km-self-preview
      [room]="room"
      *ngIf="
        getView() != 'gridparticipantsView' && room?.localParticipant?.name
      "
    ></app-km-self-preview>

    <div class="h-100 bg-black">
      <div class="d-flex h-100" *ngIf="getView() != 'gridparticipantsView'">
        <app-km-large-video
          *ngIf="getView() == 'mainView'"
          class="flex-grow-1"
          [room]="room"
          [ringing]="ringing"
          [connectingWithManager]="connectingWithManager"
        ></app-km-large-video>
      </div>

      <!-- waiting for host alert -->
      <div
        *ngIf="isWaitForHost() && wait_for_host_timer"
        style="z-index: 1"
        class="localVideoDiv screensharing-text-container"
      >
        <div
          style="
            margin-top: 22%;
            color: white;
            align-items: center;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            justify-content: center;
            display: flex;
          "
          *ngIf="wait_for_host_timer"
        >
          <div>
            <ion-icon
              style="color: white; margin-bottom: -2px"
              name="timer-outline"
            ></ion-icon>
          </div>
          <div>&nbsp;{{ wait_for_host_timer }}</div>
        </div>
        <p class="loading">Waiting for host to rejoin</p>
      </div>
      <!-- grid view of participants -->
      <app-km-grid-participant-view
        [conference]="conference"
        [navigatedView]="navigatedView"
      >
      </app-km-grid-participant-view>

      <!-- top buttons section -->
      <div
        class="topIconsBtnDiv fullscreen_btn_container showTopWindow"
        *ngIf="conference"
        [ngClass]="{ hideTopWindow: !changeMousePoint }"
      >
        <!-- left section -->
        <div>
          <div style="padding-left: 5px" class="d-flex align-items-center">
            <!-- video quality -->
            <app-km-video-quality-button
              [conference]="conference"
            ></app-km-video-quality-button>

            <!-- <button class="action_buttons">
              <ion-icon
                name="cloud-offline-outline"
                *ngIf="p2pEnabled; else noP2PIcon"
              ></ion-icon>
              <ng-template #noP2PIcon>
                <ion-icon name="cloud-outline"></ion-icon>
              </ng-template>
            </button> -->

            <!-- recording duration -->
            <!-- <div
              *ngIf="liveStreamRecording"
              class="d-flex align-items-center cursor-pointer"
              (click)="onStopRecording()"
              style="margin-left: 5px"
            >
              <div>
                <div class="recording-animation">
                  <div></div>
                </div>
              </div>
              <div class="liveStreamRecordingDuration">
                {{ liveStreamRecordDuration }}
              </div>
            </div> -->
          </div>
        </div>
        <!-- middle section -->
        <!-- meeting duration timer -->
        <app-km-meeting-duration
          *ngIf="isJoined"
          [ngStyle]="{ opacity: '1' }"
        ></app-km-meeting-duration>

        <!-- <button (click)="addParticipant()">Add</button>
        <button (click)="removeParticipant()">Remove</button> -->

        <!-- right section -->
        <div style="height: 10px; width: 10px">
          <!-- <button
            (click)="onClickFullScreen()"
            matTooltip="Fullscreen"
            class="action_buttons"
            *ngIf="
              getView() == 'gridparticipantsView';
              else dummyFullscreenButton
            "
          >
            <ion-icon
              name="expand"
              *ngIf="!isFullscreen(); else contractSvg"
            ></ion-icon>
            <ng-template #contractSvg>
              <ion-icon name="contract"></ion-icon>
            </ng-template>
          </button> -->
        </div>

        <ng-template #dummyFullscreenButton>
          <div style="height: 20px; width: 20px"></div>
        </ng-template>
      </div>

      <br />
      <!-- footer buttons section -->
      <div
        class="d-flex flex-column justify-content-center align-items-center footer_buttons_section iconBackgroundDivFullScreen"
        [ngStyle]="{
          visibility: isJoined ? 'visible' : 'hidden',
          transform:
            !showFooterControls &&
            (getView() == 'whiteBoard' || getView() == 'gridparticipantsView')
              ? 'translateY(90%)'
              : ''
        }"
        [ngClass]="{ hideBottomBtnDiv: !changeMousePoint }"
      >
        <div
          *ngIf="
            getView() == 'whiteBoard' || getView() == 'gridparticipantsView'
          "
        >
          <button
            class="togglePViewButton d-flex align-items-center action_buttons"
            [matTooltip]="showFooterControls ? 'Hide toolbar' : 'Show toolbar'"
            (click)="toogleFooterButtonsVisibility()"
          >
            <ion-icon name="chevron-up" *ngIf="!showFooterControls"></ion-icon>
            <ion-icon name="chevron-down" *ngIf="showFooterControls"></ion-icon>
          </button>
        </div>
        <div
          class="meetingActionButtons p-2"
          [ngStyle]="{
            visibility:
              !showFooterControls &&
              (getView() == 'whiteBoard' || getView() == 'gridparticipantsView')
                ? 'hidden'
                : 'visible'
          }"
        >
          <!-- unmute mic button -->
          <button
            (click)="onToggleMic()"
            class="me-2"
            *ngIf="isMicMuted(); else micOnButton"
          >
            <div
              class="lds-dual-ring"
              *ngIf="micProcessing; else micIcon"
            ></div>
            <ng-template #micIcon>
              <ion-icon name="mic-off-outline"></ion-icon>
            </ng-template>
          </button>

          <!-- mute mic button -->
          <ng-template #micOnButton>
            <button (click)="onToggleMic()" class="me-2">
              <ion-icon name="mic"></ion-icon>
            </button>
          </ng-template>

          <!-- camera mute/unmute -->
          <button
            (click)="onToggleVideo()"
            class="me-2"
            *ngIf="isVideoMuted(); else cameraOffIcon"
          >
            <div
              class="lds-dual-ring"
              *ngIf="cameraProcessing; else cameraIcon"
            ></div>
            <ng-template #cameraIcon>
              <ion-icon name="videocam-off-outline"></ion-icon>
            </ng-template>
          </button>

          <ng-template #cameraOffIcon>
            <button class="me-2" (click)="onToggleVideo()">
              <ion-icon name="videocam"></ion-icon>
            </button>
          </ng-template>

          <!-- share screen -->
          <!-- <button
            (click)="onToggleScreenshare()"
            class="me-2"
            *ngIf="!screenSharing"
          >
            <div
              class="lds-dual-ring"
              *ngIf="screenShareProcessing; else shareScreenIcon"
            ></div>
            <ng-template #shareScreenIcon>
              <mat-icon fontSet="material-icons-outlined"
                >screen_share</mat-icon
              >
            </ng-template>
          </button>

           <button
            (click)="onToggleScreenshare()"
            class="me-2"
            *ngIf="screenSharing"
          >
            <mat-icon>screen_share</mat-icon>
          </button> -->

          <!-- more option -->
          <!-- <mat-menu
            #menu="matMenu"
            yPosition="above"
            style="background-color: #283447; color: #b8c7e0"
          >
            <button mat-menu-item (click)="onClickSettings()">
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
          </mat-menu>
          <button class="me-2" [mat-menu-trigger-for]="menu">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </button> -->

          <!-- stop meeting -->
          <button (click)="stopBroadcastBtnClick()" class="stopbutton">
            <mat-icon>call_end</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div id="remoteTracksBroadcaster"></div>
  </div>

  <audio
    style="position: absolute"
    #ringer
    src="assets/sounds/phone_ring.mp3"
    loop
  ></audio>

  <audio
    src="assets/sounds/point-smooth-beep.mp3"
    id="agentAudio"
    hidden="true"
  ></audio>
</div>
