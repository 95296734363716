/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BroadcastConfig } from '../model/model';

const BROADCAST_CONTAINER = 'broadcast-container';
const BROADCAST_MINI = 'broadcast-mini';
const BROADCAST_HOME = 'broadcast-home';
const BRODACAST_CLASSROOM_CONTAINER = 'classroomLiveClassSection';

@Injectable({
  providedIn: 'root',
})
export class VideoBroadcastService {
  broadcasting: boolean = false;
  broadcastWatching: boolean = false;
  broadcastingChannel: any = null;
  autoReconnectChannel: any = null;
  broadcastMiniActive: boolean = false;
  broadcastQueue: any = {};
  startBroadcastInQueue: boolean = false;
  watchBroadcastInQueue: boolean = false;
  broadcastConfig: any = null;
  fullScreen: boolean = false;
  chnlBrodReq: any = null;
  liveHosts: any = null;
  timeLeft: number = 0;
  interval: any;
  timeOutDuration: number = 0;
  waitForHost: boolean = false;
  showingInAotWindow: boolean = false;
  private broadcastListener = new Subject<BroadcastConfig>();
  private broadcastStopListener = new Subject<string>();
  private broadcastWatchStopListener = new Subject<string>();
  private broadcastMiniShowHideListener = new Subject<boolean>();
  private stopBroadcastWatchingActionListener = new Subject<boolean>();
  private stopBroadcastActionListener = new Subject<boolean>();
  private broadcastModuleRefreshListener = new Subject<boolean>();
  private fullScreenBroadcastListener = new Subject<boolean>();
  private hideLeftBarForBroacastFullScreenListener = new Subject<boolean>();
  private fullScreenSwitchListener: Subject<boolean> = new Subject<boolean>();
  private joinLoaderListener = new Subject<boolean>();
  private timerListener = new Subject<boolean>();
  private liveClassNavigationObserver = new Subject<string>();

  constructor() {}

  getLiveClassNavigationObservable() {
    return this.liveClassNavigationObserver.asObservable();
  }

  triggerLiveclassNavigation(view: string) {
    this.liveClassNavigationObserver.next(view);
  }

  getBroadcastistener() {
    return this.broadcastListener.asObservable();
  }

  getBroadcastStopListener() {
    return this.broadcastStopListener.asObservable();
  }

  getBroadcastWatchStopListener() {
    return this.broadcastWatchStopListener.asObservable();
  }

  getBroadcastModuleRefreshListener() {
    return this.broadcastModuleRefreshListener.asObservable();
  }

  getStopBroadcastWatchActionListener() {
    return this.stopBroadcastWatchingActionListener.asObservable();
  }

  getStopBroadcastActionListener() {
    return this.stopBroadcastActionListener.asObservable();
  }

  getBroadcastMiniShowHideLIstener() {
    return this.broadcastMiniShowHideListener.asObservable();
  }

  getFullScreenSwitchListener() {
    return this.fullScreenSwitchListener.asObservable();
  }

  /**
   * show broadcast in right bottom of window like picture in picture
   */
  showBroadcastMini() {
    this.broadcastMiniActive = true;
    this.broadcastMiniShowHideListener.next(true);
  }

  /**
   * hide broadcast mini
   */
  hideBroadcastMini() {
    this.broadcastMiniActive = false;
    this.broadcastMiniShowHideListener.next(false);
  }

  /**
   * get broadcasting/broadcast watching listener
   */
  getBroadcastingChannel() {
    return this.broadcastingChannel;
  }

  /**
   * this method will return true if broadcasting
   */
  isBroadcasting() {
    return this.broadcasting;
  }

  /**
   * this method will return true if user watching a broadcast
   */
  isBroadcastWatching() {
    return this.broadcastWatching;
  }

  /**
   * this method returns true while broadcastmini is active
   */
  isBroadcastMiniActive() {
    return this.broadcastMiniActive;
  }

  pauseTimer() {
    this.timeLeft = 0;
    clearInterval(this.interval);
  }

  /**
   * move broadcast window inside channel chat parent node
   * @param parentNode
   */
  setBroadcastViewToClassroomPage() {
    this.hideBroadcastMini();
    let broadcast_classroom_container: any = document.getElementById(
      BRODACAST_CLASSROOM_CONTAINER
    );
    let broadcast_container = document.getElementById(BROADCAST_CONTAINER);
    broadcast_classroom_container.appendChild(broadcast_container);
  }

  /**
   * show broadcast window in BroadcastMini
   */
  showInBroacastMini() {
    if (!(this.broadcasting || this.broadcastWatching)) {
      return;
    }
    if (this.fullScreen) {
      this.fullScreen = false;
      this.fullScreenBroadcastListener.next(false);
    }
    let broadcastMini: any = document.getElementById(BROADCAST_MINI);
    broadcastMini.appendChild(document.getElementById(BROADCAST_CONTAINER));
    this.showBroadcastMini();
  }

  /**
   * on stop broadcast return broadcast container to its home position
   * if BroadcastMini is in open stage, then close
   */
  onStopBroadcast() {
    this.pauseTimer();

    // let home: any = document.getElementById(BROADCAST_HOME);
    // home.appendChild(document.getElementById(BROADCAST_CONTAINER));

    // this.broadcastStopListener.next(this.broadcastingChannel.id);

    this.broadcasting = false;
    this.broadcastingChannel = null;
    this.broadcastConfig = null;
    this.chnlBrodReq = null;
    this.broadcastModuleRefreshListener.next(true);
    if (this.broadcastMiniActive) {
      this.hideBroadcastMini();
    }
    /**
     * if there is any broadcast in queue start that
     */
    this.doBroadcastInQueue();
  }

  /**
   * on stop broadcast return broadcast container to its home position
   * if BroadcastMini is in open stage, then close
   */
  onStopViewingBroadcast() {
    let home: any = document.getElementById(BROADCAST_HOME);
    home.appendChild(document.getElementById(BROADCAST_CONTAINER));

    this.broadcastWatchStopListener.next(this.broadcastingChannel.id);
    this.broadcastWatching = false;
    this.broadcastingChannel = null;
    this.broadcastConfig = null;
    this.broadcastModuleRefreshListener.next(true);
    if (this.broadcastMiniActive) {
      this.hideBroadcastMini();
    }
    /**
     * if there is any broadcast in queue start that
     */
    this.doBroadcastInQueue();
  }

  doBroadcastInQueue() {
    if (this.watchBroadcastInQueue) {
      setTimeout(() => {
        console.log('do broadcast in queue');

        this.broadcastQueue = {};
        this.watchBroadcastInQueue = false;
      }, 100);
    }

    if (this.startBroadcastInQueue) {
      setTimeout(() => {
        this.broadcastQueue = {};
        this.startBroadcastInQueue = false;
      }, 100);
    }
  }

  stopBroadcastSession() {
    console.log('stop broadcast session called');

    this.stopWatchingBroadcast();
    this.stopActiveBroadcast();
  }
  /**
   * to stop watching broadcast session
   */
  stopWatchingBroadcast(autoreconnectEnabled: boolean = false) {
    if (autoreconnectEnabled) {
      this.autoReconnectChannel = this.broadcastingChannel;
    } else {
      this.autoReconnectChannel = null;
    }
    if (this.broadcastWatching) {
      this.stopBroadcastWatchingActionListener.next(true);
    }
  }

  /**
   * stop active broadcastsession
   */
  stopActiveBroadcast() {
    if (this.broadcasting) {
      this.stopBroadcastActionListener.next(true);
    }
  }

  /**
   * force stop broadcasting
   * call this only while user logout
   */
  fStopBroadcast: boolean = false;
  forceStopBroadcast() {
    if (!this.fStopBroadcast) {
      this.fStopBroadcast = true;
      this.stopActiveBroadcast();
      this.stopWatchingBroadcast();
      setTimeout(() => {
        this.fStopBroadcast = false;
      }, 100);
    }
  }

  /***Broadcast FullScreen*/
  broadcastFullScreen(value: boolean) {
    // console.log(value);
    this.fullScreen = value;
    this.broadcastMiniShowHideListener.next(false);
    if (this.isBroadcastMiniActive()) {
      if (this.broadcastConfig && this.broadcastingChannel) {
      }
    } else {
      this.fullScreenBroadcastListener.next(value);
    }
  }

  getFullScreenBroadcastListener() {
    return this.fullScreenBroadcastListener.asObservable();
  }

  hideSideLeftBar(value: boolean) {
    this.hideLeftBarForBroacastFullScreenListener.next(value);
  }
  getHideLeftBarForBroacastFullScreenListener() {
    return this.hideLeftBarForBroacastFullScreenListener;
  }
  /**
   * return full screen or not request
   */
  isBroadcastFullScreen() {
    return this.fullScreen;
  }

  joinBroadcastLoaderFunction(value: boolean) {
    this.joinLoaderListener.next(value);
  }
  getjoinBroadcastLoader() {
    return this.joinLoaderListener.asObservable();
  }

  isFireFox() {
    let browser = this.getBrowserName();
    return browser == 'Firefox';
  }

  getBrowserName() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') != -1) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }
  setWaitForHost(waitForHost: any) {
    return waitForHost;
  }
  gettimerObservable() {
    return this.timerListener.asObservable();
  }
  jointimerObservableFunction(value: boolean) {
    this.waitForHost = value;
    this.timerListener.next(value);
  }

  /**
   * set broadcasting channel and broadcasting variable true
   * move video broadcast window to parentNode user given
   * and call startBroadcast in in broadcaster component
   * @param broadcastConfig
   * @param channel
   * @param start
   */
  broadcast(broadcastConfig: BroadcastConfig) {
    if (!this.broadcasting && !this.broadcastWatching) {
      /**
       * no active broadcast
       */
      // this.broadcastingChannel = channel;
      this.broadcastConfig = broadcastConfig;
      this.broadcasting = true;
      // this.setBroadcastViewToClassroomPage();
      this.broadcastListener.next(broadcastConfig);
    } else {
      /**
       * there is a broadcast running
       * stop that and start new one
       */
      this.stopBroadcastSession();
      this.broadcastQueue = {
        config: broadcastConfig,
        // channel: channel,
      };
      this.startBroadcastInQueue = true;
    }
  }
}
