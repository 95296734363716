/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';

import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MdePopoverModule } from '@material-extended/mde';
import { CustomComponentsModule } from '../custom-components/custom-components.module';
import { BroadcasterComponent } from './components/broadcaster/broadcaster.component';
import { KmeetDeviceSettingsComponent } from './components/kmeet-device-settings/kmeet-device-settings.component';
import { KmErrorAlertsComponent } from './components/meeting/km-error-alerts/km-error-alerts.component';
import { KmFooterButtonsComponent } from './components/meeting/km-footer-buttons/km-footer-buttons.component';
import { KmGridParticipantViewComponent } from './components/meeting/km-grid-participant-view/km-grid-participant-view.component';
import { KmLargeVideoComponent } from './components/meeting/km-large-video/km-large-video.component';
import { KmMeetingDurationComponent } from './components/meeting/km-meeting-duration/km-meeting-duration.component';
import { KmParticipantBoxComponent } from './components/meeting/km-participant-box/km-participant-box.component';
import { KmSelfPreviewComponent } from './components/meeting/km-self-preview/km-self-preview.component';
import { KmVideoQualityButtonComponent } from './components/meeting/km-video-quality-button/km-video-quality-button.component';
import { MicLevelIndicatorComponent } from './components/mic-level-indicator/mic-level-indicator.component';
import { StopAlertComponent } from './components/stop-alert/stop-alert.component';

@NgModule({
  declarations: [
    BroadcasterComponent,
    MicLevelIndicatorComponent,
    StopAlertComponent,
    KmeetDeviceSettingsComponent,
    KmLargeVideoComponent,
    KmErrorAlertsComponent,
    KmParticipantBoxComponent,
    KmMeetingDurationComponent,
    KmFooterButtonsComponent,
    KmGridParticipantViewComponent,
    KmVideoQualityButtonComponent,
    KmSelfPreviewComponent,
  ],
  imports: [
    MdePopoverModule,
    CommonModule,
    MatMenuModule,
    IonicModule,
    MatTooltipModule,
    MatIconModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    CustomComponentsModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: { hasBackdrop: true } },
  ],
  exports: [BroadcasterComponent, KmeetDeviceSettingsComponent],
})
export class ChannelVideoBroadcastModule {}
