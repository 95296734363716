import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActiveCall } from 'src/app/model/Calls';
import { DepartmentsResp } from 'src/app/model/DepartmentsResp';
import { KayoolMeetService } from 'src/app/modules/kayool-meet/service/kayool-meet.service';
import { VideoBroadcastService } from 'src/app/modules/kayool-meet/service/video-broadcast.service';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-video-call-form',
  templateUrl: './video-call-form.component.html',
  styleUrls: ['./video-call-form.component.css'],
})
export class VideoCallFormComponent implements OnInit, OnDestroy {
  _mounted: boolean = false;
  userName: string = '';
  loading: boolean = false;
  clientID = null;
  activeCall: ActiveCall = null;
  isCancelled: boolean = false;
  callingDepartmentId: string = null;

  // for departments
  departments: DepartmentsResp = { body: [], length: 0 };
  departmentId: string = null;
  loadDepartments: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<VideoCallFormComponent>,
    private kayoolMeetService: KayoolMeetService,
    @Inject(MAT_DIALOG_DATA) data,
    private websocketService: WebsocketService,
    private videoBroadcastService: VideoBroadcastService,
    private dialog: MatDialog
  ) {
    this.clientID = data.clientID;
    this.callingDepartmentId = data.deptId;
  }

  ngOnInit(): void {
    this._mounted = true;
    this.getDepartments();
  }

  ngOnDestroy(): void {
    this._mounted = false;
  }

  getDepartments() {
    if (this.loadDepartments) return;
    this.loadDepartments = true;

    this.websocketService
      .getDepartmentsApi(this.clientID)
      .then((res: DepartmentsResp) => {
        this.departments.body.push({ id: null, name: 'General' });
        for (let dept of res.body) {
          this.departments.body.push(dept);
        }
        this.departments.length = res.length;
        this.loadDepartments = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.loadDepartments = false;
        if (err.status != 401) {
          setTimeout(() => {
            this.getDepartments();
          }, 3000);
        }
      });
  }

  selectValue(event: any) {
    this.departmentId = event.target.value;
  }

  onClose() {
    this.isCancelled = true;
    this.dialogRef.close();
    this.kayoolMeetService.triggerSessionStop(false);
  }

  onStartVideoCall() {
    if (!this.clientID || !this.userName || this.userName.length < 3) {
      return;
    }

    this.isCancelled = false;
    // this.loading = true;
    let deptId = this.callingDepartmentId
      ? this.callingDepartmentId
      : this.departmentId;

    console.log('-------------------');
    console.log('calling to dept', deptId);
    console.log('-------------------');

    this.websocketService
      .addNewCall(this.userName, this.clientID, deptId)
      .then((res: ActiveCall) => {
        this.activeCall = res;
        this.videoBroadcastService.broadcast({
          avatarUrl: '',
          classroom: null,
          firstName: this.userName,
          lastName: '',
          isUser: true,
          kollegenetUserID: 0,
          rejoinAsBroadcaster: false,
          room: res.id,
          twoSideVideo: true,
          userID: 0,
          ring: true,
          startWithVideoMuted: true,
        });
        this.loading = false;
        this.dialogRef.close();
      })
      .catch((err) => {
        console.error(err);
        if (
          err.error.message ==
            'Account disabled. Please contact your service provider.' ||
          err.error.message == 'Client Not Found'
        ) {
          this.clientID = null;
          this.websocketService.triggerClientRemovedOrDisabledObservable(true);
          this.dialogRef.close();
          return;
        }

        if (err.error.message == 'All staffs are busy') {
          this.isCancelled = true;
          this.kayoolMeetService.triggerSessionStop(true);
          this.dialogRef.close();
          return;
        }

        setTimeout(() => {
          if (this._mounted) this.onStartVideoCall();
        }, 2000);
      });
  }
}
