<div class="vertical-view-container">
  <div *ngFor="let p of participants">
    <app-km-participant
      [room]="room"
      [participant]="p"
      *ngIf="
        participants.length < 3 ||
        (participants.length >= 3 && !p?.identity?.startsWith('agent-'))
      "
    ></app-km-participant>
  </div>
</div>
