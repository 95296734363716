<div class="form-container">
  <div class="d-flex headerSection">
    <h6
      style="
        display: contents;
        font-family: Oswald, sans-serif !important ;
        font-size: 1rem !important;
      "
    >
      Enter Your Name
    </h6>

    <div class="closeBtn" (click)="onClose()">
      <ion-icon name="close-outline" style="font-size: 25px"></ion-icon>
    </div>
  </div>

  <div class="form-group">
    <input
      class="form-control"
      type="text"
      name="user_name"
      maxlength="60"
      autocomplete="off"
      [(ngModel)]="userName"
      placeholder="Name"
      (keydown.enter)="onStartVideoCall()"
    />
  </div>

  <div
    *ngIf="!callingDepartmentId && departments.length > 0"
    style="
      flex-direction: row;
      align-items: center;
      display: flex;
      margin-top: 15px;
    "
  >
    <div style="flex: 1">
      <label class="form-label fw-bold">Select Department</label>
    </div>
    <div style="flex: 1">
      <select
        class="form-control form-select"
        name="department"
        (change)="selectValue($event)"
      >
        <option
          *ngFor="let dept of departments.body; index as i"
          [value]="dept.id"
        >
          {{ dept?.name }}
        </option>
      </select>
    </div>
  </div>

  <div
    style="margin: 10px 0px 10px 0px; display: flex; justify-content: flex-end"
  >
    <button
      [disabled]="!userName || userName.length < 3"
      type="submit"
      class="btn btn-primary text-light"
      (click)="onStartVideoCall()"
      style="
        cursor: pointer;
        background-color: #ffa149 !important;
        width: 165px;
        border-color: #ffa149 !important;
      "
    >
      <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
      <span *ngIf="!loading">Start Video Call</span>
    </button>
  </div>

  <div class="d-flex justify-content-center" style="margin-top: 15px">
    <span
      >Powered By
      <strong
        ><a href="https://connectnow.ai" target="_blank">ConnectNow</a></strong
      ></span
    >
  </div>
</div>
