import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'call/:clientId',
    component: LandingPageComponent,
  },
  {
    path: 'call/:clientId/dept/:deptId',
    component: LandingPageComponent,
  },
  {
    path: 'call/u/:uname',
    component: LandingPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
